import React, { useEffect, useState } from 'react'
import GlobalFooter from './components/GlobalFooter'
import GlobalNav from './components/GlobalNav'
import LogoHeader from './components/LogoHeader'
import applyImg from '../assets/apply-img-3.jpg'
import RadioText from './components/RadioInput'
import ApplicationsHeader from './components/ApplicationsHeader'
import { useNavigate } from 'react-router-dom'
import { TitledInput, TitledInputDate, TitledInputHalf, TitledInputRadio, TitledInputMsg } from './components/TitledInput'
import ApplyStep4 from './ApplyStep4'
import axios from 'axios'
import myGa from './myGa'


export const Apply = () => {

    // useEffect(() => {
    //     myGa();

    //     return () => {

    //     }
    // }, [])


    const [id, setID] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const [productDesc, setProductDesc] = useState()
    const [productPrice, setProductPrice] = useState()
    const [productProfit, setProductProfit] = useState()
    const [productRevenue, setProductRevenue] = useState()

    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault()
        if (!productDesc ||
            !productPrice ||
            !productProfit ||
            !productRevenue
        ) {
            return setErrorMsg('Please note that all fields Must be filled to Proceed')
        }

        setIsLoading(true)
        axios.patch(`https://vcc3-backend.onrender.com/api/apply/${id}`, {
            productDesc,
            productPrice,
            productProfit,
            productRevenue,

        })

            .then((res) => {
                if (res.status === 200) {
                    // localStorage.setItem('user', JSON.stringify(res.data))
                    // setUser(res.data)
                    navigate('/Apply-Step-4')
                }
                console.log(res)
            })
            .catch((error) => {
                console.log('++++++++++++++++')
                console.log(error)
            })

    }

    useEffect(() => {
        if (!id) {
            const appID = JSON.parse(localStorage.getItem('form_id'))
            setID(appID)
        }

        return () => {

        }
    }, [])



    return (
        <div className=' w-[80%] sm:w-[100%] md:w-[100%] sm:mt-0   mx-auto mt-5 '>
            <ApplicationsHeader
                step={'3'}
                title={'Product Business Case'}
            />
            {errorMsg && <p className='text-red-500 mulish text-sm'>{errorMsg}</p>}
            <form className=''>
                <TitledInputMsg
                    title={'What problem does your company solve? (500 words only) '}
                    placeholder={''}
                    onChange={(e) => setProductDesc(e.target.value)}

                />
                <TitledInputMsg
                    title={'What is the selling price of your product or service? '}
                    placeholder={'If you are not yet operational, state the price that you intend to sell your product/ services for.'}
                    onChange={(e) => setProductPrice(e.target.value)}
                />
                <TitledInputMsg
                    title={'What is the profit margin realised on each unit sold? '}
                    placeholder={''}
                    onChange={(e) => setProductProfit(e.target.value)}
                />
                <TitledInputMsg
                    title={'How much revenue are you generating annually? '}
                    placeholder={'If you are not yet generating revenue please state N/A'}
                    onChange={(e) => setProductRevenue(e.target.value)}
                />


                <input type={'submit'} value={isLoading ? 'Please Wait' : 'Proceed to step 4'} className='uppercase  p-2 text-sm hover:opacity-70 bg-[#00A3FF] text-white mt-10 font-semibold cursor-pointer rounded-md flex justify-center w-40' onClick={handleSubmit} />



            </form>




        </div>
    )
}


const ApplyStep3 = () => {




    return (
        <div className='reletive'>
            <GlobalNav />


            <div className='h-[fit-content] w-full sm:pt-[20%] md:pt-[5%] flex '>
                <div className='h-[screen] sm:hidden pt-[2%] -z-20 w-[25%] bg-[#ADDC02]'>
                    <img src={applyImg} className='w-[100%]' />

                </div>
                <div className='flex flex-1 flex-col mx-auto px-5 sm:w-[100%] relative pt-[13%]'>


                    <div className='flex sm:w-[100%] sm:justify-start justify-center md:w-[100%] md:justify-start'>
                        <LogoHeader
                            header={'2023 Application Form'}
                            description={'Please Remember to fill all the fields to complete applying.'}
                        />

                    </div>
                    {/* <div className='flex justify-left pl-[10%]' >
                        <RadioText
                            label={'You have read and understood our data privacy policy and the terms & conditions for the  Value Creation Challenge 2023'}

                            name={'AgreeToTerms'} />

                    </div> */}

                    <div className='flex justify-start  w-full' >
                        <Apply />
                    </div>


                    <div className='h-[10vh]'>

                    </div>
                </div>
            </div>

            <GlobalFooter />
        </div>
    )
}

export default ApplyStep3