import React from 'react'
import vcc3logo from '../../assets/VCC_Logo_2023.png'
import { buildTimeValue } from '@testing-library/user-event/dist/utils'


const LogoHeader = ({ header, description }) => {


    return (
        <div className='flex  w-[80%] sm:flex-col sm:mt-10 md:mt-14 items-center mb-10'>
            <div className='w-[100%]   '>
                <img src={vcc3logo} className='w-[90%] ' />
            </div>
            <div className='flex flex-col sm:mt-4 w-[100%]'>
                <h1 className='montserrat  mfont-bold text-[#58B43F] text-[2rem] sm:text-[1.2rem] md:text-[1rem] lg:text-[1rem]'>
                    {header}
                </h1>
                <h3 className='sm:text-sm md:text-xs'>
                    {description}
                </h3>
            </div>


        </div>
    )
}

export default LogoHeader