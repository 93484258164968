import React, { useContext, useEffect, useState } from 'react'
import GlobalFooter from './components/GlobalFooter'
import GlobalNav from './components/GlobalNav'
import LogoHeader from './components/LogoHeader'
import applyImg from '../assets/apply-img-2.jpg'
import RadioText from './components/RadioInput'
import ApplicationsHeader from './components/ApplicationsHeader'
import { useNavigate } from 'react-router-dom'
import { TitledInput, TitledInputDate, TitledInputHalf, TitledInputRadio2, TitledInputSelect, TitledInputMsg } from './components/TitledInput'
import axios from 'axios'
import { UserContext } from '../context/UserContext'
import myGa from './myGa'


export const Apply = () => {
    // useEffect(() => {
    //     myGa();

    //     return () => {

    //     }
    // }, [])



    const [id, setID] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const [businessName, setBusinessName] = useState()

    const [businessStartDate, setBusinessStartDate] = useState()
    const [businessEmail, setBusinessEmail] = useState()
    const [businessAddress, setBusinessAddress] = useState()
    const [businessType, setBusinessType] = useState()
    const [businessRegType, setBusinessRegType] = useState()
    const [businessDesc, setBusinessDesc] = useState()
    const [businessDescStructure, setBusinessDescStructure] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault()
        if (!businessName ||
            !businessStartDate ||
            !businessEmail ||
            !businessAddress ||

            !businessType ||
            !businessRegType ||
            !businessDesc ||
            !businessDescStructure) {
            return setErrorMsg('Please note that all fields Must be filled to Proceed')
        }

        setIsLoading(true)
        axios.patch(`https://vcc3-backend.onrender.com/api/apply/${id}`, {
            businessName,
            businessStartDate,
            businessEmail,
            businessAddress,
            businessType,
            businessRegType,
            businessDesc,
            businessDescStructure,
        })
            .then((res) => {
                if (res.status === 200) {

                    navigate('/Apply-Step-3')
                }

            })
            .catch((error) => {

            })

    }






    const options = [
        { value: 'Financial Technology (Fintech)', label: 'Financial Technology (Fintech)' },
        { value: 'Emerging Technology', label: 'Emerging Technology ' },
        { value: 'Creative  Industry', label: 'Creative  Industry' },
        { value: 'Sustainablity Pillar', label: 'Sustainablity Pillar' }
    ]

    const handleChange = (selectedOption) => {
        setBusinessType(selectedOption.value);
        // console.log(`Option selected:`, selectedOption);
    };
    useEffect(() => {
        if (!id) {
            const appID = JSON.parse(localStorage.getItem('form_id'))
            setID(appID)
        }

        return () => {

        }
    }, [])

    return (
        <div className=' w-[80%] sm:w-[100%] md:w-[100%] sm:mt-0 mx-auto mt-5'>
            <ApplicationsHeader
                step={'2'}
                title={'Business Information'}
            />
            {errorMsg && <p className='text-red-500 mulish text-sm'>{errorMsg}</p>}
            <form className=''>
                <TitledInput
                    title={'Name of Business'}
                    placeholder={'Please provide us with name of your business'}
                    onChange={(e) => setBusinessName(e.target.value)}
                />
                <div className='flex sm:flex   md:flex-col sm:flex-col justify-between'>
                    <TitledInputDate
                        title={' Start Date'}
                        placeholder={'When did you start the business'}
                        onChange={(e) => setBusinessStartDate(e.target.value)}
                    />
                    <TitledInputHalf
                        title={'Business Email'}
                        placeholder={'Your Business Email Account'}
                        onChange={(e) => setBusinessEmail(e.target.value)}
                    />
                </div>
                <TitledInput
                    title={'Physical Address'}
                    placeholder={'Please provide us with your business address or contact address*'}
                    onChange={(e) => setBusinessAddress(e.target.value)}
                />
                <div className='flex sm:flex gap-y-5  md:flex-col sm:flex-col justify-between'>
                    <TitledInputSelect
                        title={'What pillar does your busines fall under'}
                        options={options}
                        onChange={handleChange}

                    />
                    <TitledInputRadio2
                        title={'Is your business registered?'}
                        onChange={(e) => setBusinessRegType(e.target.value)}

                    />
                </div>
                <TitledInputMsg
                    title={'If your business is registered , please advise legal structure. '}
                    placeholder={'Who are the directors and shareholders?'}
                    onChange={(e) => setBusinessDescStructure(e.target.value)}
                />
                <TitledInputMsg
                    title={'Please provide a brief description about your business? '}
                    placeholder={''}
                    onChange={(e) => setBusinessDesc(e.target.value)}
                />



                <input type={'submit'} value={isLoading ? 'Please Wait' : 'Proceed to step 3'} className='uppercase  p-2 text-sm hover:opacity-70 bg-[#00A3FF] text-white mt-10 font-semibold cursor-pointer rounded-md flex justify-center w-40' onClick={handleSubmit} />



            </form>




        </div>
    )
}


const ApplyStep2 = () => {


    return (
        <div className='reletive'>
            <GlobalNav />


            <div className='h-[fit-content] w-full sm:pt-[20%] md:pt-[5%] flex '>
                <div className='h-[screen] sm:hidden  pt-[2%] -z-20 w-[25%] bg-[#ADDC02]'>
                    <img src={applyImg} className='w-[100%]' />

                </div>
                <div className='flex flex-1 flex-col mx-auto  px-5  pt-[13%]'>


                    <div className='flex  justify-center md:w-[100%] md:justify-start  sm:w-[100%] sm:justify-start'>
                        <LogoHeader
                            header={'2023 Application Form'}
                            description={'Please Remember to fill all the fields to complete applying.'}
                        />

                    </div>
                    {/* <div className='flex justify-left pl-[10%]' >
                        <RadioText
                            label={'You have read and understood our data privacy policy and the terms & conditions for the  Value Creation Challenge 2023'}

                            name={'AgreeToTerms'} />

                    </div> */}

                    <div className='flex justify-start  w-full' >
                        <Apply />
                    </div>


                    <div className='h-[10vh]'>

                    </div>
                </div>
            </div>

            <GlobalFooter />
        </div>
    )
}

export default ApplyStep2