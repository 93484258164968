import React from 'react'
import GlobalFooter from './components/GlobalFooter'
import GlobalNav from './components/GlobalNav'
import LogoHeader from './components/LogoHeader'
import applyImg from '../assets/apply-img-1.jpg'
import RadioText from './components/RadioInput'
import ApplicationsHeader from './components/ApplicationsHeader'
import { useNavigate } from 'react-router-dom'
import { TitledInput, TitledInputDate, TitledInputHalf, TitledInputRadio, TitledInputMsg } from './components/TitledInput'
import SolidButton from './components/SolidButton'


export const Apply = () => {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault()

        navigate('/')
    }
    const handleLoginNav = (e) => {
        e.preventDefault()

        navigate('/Apply-Login')
    }



    return (
        <div className=' w-[80%] sm:w-[100%] md:w-[100%] mx-auto mt-5'>
            <ApplicationsHeader
                step={''}
                title={'You have Successfully Registered'}
            />
            <div>
                <p className='mulish mb-10 sm:w-[100%] md:w-[100%] w-[60%]'>
                    Congratulations! You are just getting started. <span className='font-bold'>Please login to start applying now!</span>
                </p>
                <SolidButton
                    buttonTitle={'Login'}
                    handleClick={handleLoginNav}
                    btnbgColor={'#00A3FF'}
                    textColor='#FFFFFF'
                />

            </div>




        </div>
    )
}


const SignSuccess = () => {




    return (
        <div className='reletive'>
            <GlobalNav />


            <div className='h-[fit-content] md:h-[90vh] sm:pt-[20%] w-full flex '>
                <div className='h-[screen]  sm:hidden pt-[2%] -z-20 w-[25%] bg-[#ADDC02]'>
                    <img src={applyImg} className='w-[100%]' />

                </div>
                <div className='flex flex-1 flex-col mx-auto px-5 relative pt-[13%]'>


                    <div className='flex sm:w-[100%] md:mt-[5%] sm:justify-start justify-center md:w-[100%] md:justify-start'>
                        <LogoHeader
                            header={'Application Signup'}
                            description={'Please Remember to fill in all the fields to complete  your applicaction.'}
                        />

                    </div>
                    {/* <div className='flex justify-left pl-[10%]' >
                        <RadioText
                            label={'You have read and understood our data privacy policy and the terms & conditions for the  Value Creation Challenge 2023'}

                            name={'AgreeToTerms'} />

                    </div> */}

                    <div className='flex justify-start  w-full' >
                        <Apply />
                    </div>


                    <div className='h-[10vh]'>

                    </div>
                </div>
            </div>

            <GlobalFooter />
        </div>
    )
}

export default SignSuccess;