import React, { useContext, useState } from "react";

import LandingBg from "../../assets/hero_bg.jpg";
import vcc_logo from "../../assets/VCC_Logo_2023.png";
import SolidButton from "./SolidButton";
// import { CountDown } from "./CountDown";
import CountdownTimer from './CountDownTimer';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { buttonHide, timerDate } from './buttonHide'



const LandingHero = () => {

    console.log(buttonHide)
    const { user } = useContext(UserContext)
    const navigate = useNavigate();

    const handleApply = () => {
        console.log('Apply clicked')
        navigate(user ? '/Apply-Step-1' : '/Apply-Login')
    }


    // const SIX_WEEKS_IN_MS = 42 * 24 * 60 * 60 * 1000;
    // const NOW_IN_MS = new Date().getTime();

    // const dateTimeAfterSixWeeks = NOW_IN_MS + SIX_WEEKS_IN_MS;



    return (
        <div
            id="Home"
            className="h-[100vh] md:h-[80vh] sm:h-[100vh] lg:h-[80vh] flex items-center "
            style={{
                backgroundPositionY: "10px",
                backgroundPositionX: "",
                backgroundImage: `url(${LandingBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",

            }}
        >
            <div className="container mx-[10%]">
                <div className="flex flex-col w-[50%] md:w-[90%]  sm:flex sm:justify-center sm:w-[100%] gap-y-5 mt-[17%]">
                    <div className="mb-5 sm:flex sm:mt-[30%] sm:justify-center ">
                        <img src={vcc_logo} className="h-[100px] sm:h-auto sm:w-[100%] lg:h-auto lg:w-[100%] " />
                    </div>

                    <p className="mulish text-sm text-[#4F4F4F  w-[100%]">Value Creation Challenge (VCC) is a nationwide incubation program of Eight2Five powered by Old Mutual, in partnership with British Council, British Embassy and EFT. The program provides professional business development and learning opportunities, connections and financial support to entrepreneurs that have businesses that are no older than 5 years.</p>
                    <div className="">
                        <CountdownTimer targetDate={timerDate} />
                    </div>
                    {buttonHide ?
                        <SolidButton buttonTitle={"Apply now"} textColor="#FFFFFF" btnbgColor="#00A3FF" handleClick={() => { handleApply() }} /> : <></>
                    }
                </div>
            </div>
        </div>
    );
};

export default LandingHero;
