import React from "react";

const LineButton = ({ buttonTitle, onClick }) => {
  return (
    <div
      className=" bg-transparent border-2 border-[#58B43F] border-solid text-[#58B43F] uppercase p-2 text-sm hover:bg-[#37AA18] hover:border-[#37AA18] hover:text-[#FFF] font-semibold cursor-pointer rounded-md flex justify-center w-40"
      onClick={onClick}
    >
      {buttonTitle}
    </div>
  );
};

export default LineButton;
