import React from 'react'

const ApplicationsHeader = ({ step, title }) => {
    return (
        <div className='text-[#58B43F] montserrat mb-5'>
            <h1 className=' font-bold text-base uppercase '>Step : {step}</h1>
            <h1 className=' font-light text-3xl ' >{title}</h1>
        </div>
    )
}

export default ApplicationsHeader