import React from 'react'
import RadioInput from './RadioInput'
import Select from 'react-select'


export const TitledInput = ({ title, placeholder, value, onChange }) => {
    return (
        <div className='w-full mt-3  sm:w-[100%] md:w-[100%]'>
            <h1 className='pl-4 font-bold first-letter:capitalize mb-2 ' >{title}</h1>
            <input
                type={'text'}
                className='border-b-2 pl-4 sm:text-sm  border-black w-full bg-[#F8F9FA] h-10 text-black mb-5 outline-none  focus:bg-[#2FBF0020] focus:border-[#2FBF00]'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required


            />
        </div>
    )
}
export const TitledInputHalf = ({ title, placeholder, value, onChange }) => {
    return (
        <div className='w-[47%] mt-3  sm:w-[100%] md:w-[100%]'>
            <h1 className='pl-4 font-bold first-letter:capitalize mb-2 ' >{title}</h1>
            <input
                type={'text'}
                className='border-b-2 pl-4 sm:text-sm  border-black w-full bg-[#F8F9FA] h-10 text-black mb-5 outline-none  focus:bg-[#2FBF0020] focus:border-[#2FBF00]'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required


            />
        </div>
    )
}
export const TitledInputPhone = ({ title, placeholder, value, onChange, error }) => {
    return (
        <div className='w-[47%] mt-3  sm:w-[100%] md:w-[100%]'>
            <h1 className='pl-4 sm:text-sm font-bold first-letter:capitalize mb-2 ' >{title}</h1>
            <input
                type={'number'}
                className='border-b-2 pl-4 sm:text-sm  border-black w-full bg-[#F8F9FA] h-10 text-black mb-5 outline-none  focus:bg-[#2FBF0020] focus:border-[#2FBF00]'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required

            />
            <p className='text-red-500 mulish text-sm'>
                {error}
            </p>
        </div>
    )
}
export const TitledInputEmail = ({ title, placeholder, value, onChange, error }) => {
    return (
        <div className='w-[47%] mt-3 sm:w-[100%] md:w-[100%]'>
            <h1 className='pl-4 sm:text-sm font-bold first-letter:capitalize mb-2 ' >{title}</h1>
            <input
                type={'email'}
                className='border-b-2 pl-4 sm:text-sm  border-black w-full bg-[#F8F9FA] h-10 text-black mb-5 outline-none  focus:bg-[#2FBF0020] focus:border-[#2FBF00]'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required

            />
            <p className='text-red-500 mulish text-sm'>
                {error}
            </p>
        </div>
    )
}
export const TitledInputPass = ({ title, placeholder, value, onChange }) => {
    return (
        <div className='w-[47%] mt-3  sm:w-[100%] md:w-[100%]'>
            <h1 className='pl-4 sm:text-sm font-bold first-letter:capitalize mb-2 ' >{title}</h1>
            <input
                type={'password'}
                className='border-b-2 pl-4 sm:text-sm  border-black w-full bg-[#F8F9FA] h-10 text-black mb-5 outline-none  focus:bg-[#2FBF0020] focus:border-[#2FBF00]'
                placeholder={placeholder}
                value={value}
                onChange={onChange}

            />
        </div>
    )
}
export const TitledInputSelect = ({ title, placeholder, options, onChange }) => {
    return (
        <div className='w-[47%] mt-3 sm:w-[100%] md:w-[100%]'>
            <h1 className='pl-4 sm:text-sm font-bold first-letter:capitalize mb-2 ' >{title}</h1>
            <Select
                classNames={{
                    control: (state) =>
                        state.isFocused ? 'border-none' : 'border-none',
                }}
                options={options}
                className=''
                onChange={onChange}
                required

            />
        </div>
    )
}
export const TitledInputDate = ({ title, placeholder, value, onChange }) => {
    return (
        <div className='w-[47%] mt-3 sm:w-[100%] md:w-[100%]'>
            <h1 className='pl-4 sm:text-sm font-bold first-letter:capitalize mb-2 ' >{title}</h1>
            <input
                type={'date'}
                className='border-b-2 pl-4 sm:text-sm  placeholder:capitalize border-black w-full bg-[#F8F9FA] h-10 text-black mb-5 outline-none  focus:bg-[#2FBF0020] focus:border-[#2FBF00]'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required


            />
        </div>
    )
}
export const TitledInputRadio = ({ title, placeholder, value, onChange }) => {

    return (
        <div className='w-[47%] mt-3 sm:w-[100%] md:w-[100%]'>
            <h1 className='pl-4 sm:text-sm font-bold first-letter:capitalize mb-2 ' >{title}</h1>
            <div className='flex justify-between h-10 items-center' onChange={onChange}>
                <RadioInput
                    label={'Male'}
                    name={'gender'}
                    value={'Male'}



                />
                <RadioInput
                    label={'Female'}
                    name={'gender'}
                    value={'Female'}
                />
                <RadioInput
                    label={'Other'}
                    name={'gender'}
                    value={'Other'}

                />

            </div>

        </div >
    )
}



export const TitledInputRadio2 = ({ title, placeholder, value, onChange }) => {
    return (
        <div className='w-[47%] mt-3 sm:w-[100%] md:w-[100%]'>
            <h1 className='pl-4 sm:text-sm font-bold first-letter:capitalize mb-2 ' >{title}</h1>
            <div className='flex justify-between h-10 items-center' onChange={onChange}>
                <RadioInput
                    label={'PVT(LTD)'}
                    name={'Registerd_as'}
                    value={'PVT(LTD)'}



                />
                <RadioInput
                    label={'PBC'}
                    name={'Registerd_as'}
                    value={'PBC'}
                />
                <RadioInput
                    label={'Other'}
                    name={'Registerd_as'}
                    value={'Other'}
                />

            </div>

        </div >
    )
}
export const TitledInputMsg = ({ title, placeholder, value, onChange }) => {
    return (
        <div className='w-full mt-3 sm:w-[100%] md:w-[100%]'>
            <h1 className='pl-4 sm:text-sm font-bold first-letter:capitalize mb-2 ' >{title}</h1>
            <textarea
                type={'text'}
                className='border-b-2 pl-4 pt-2 sm:text-sm  border-black w-full bg-[#F8F9FA] h-20  text-black mb-5 outline-none  focus:bg-[#2FBF0020] focus:border-[#2FBF00]'
                placeholder={placeholder}
                value={value}
                multipleline
                required
                onChange={onChange}

            />
        </div>
    )
}

