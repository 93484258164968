import { getValue } from '@testing-library/user-event/dist/utils'
import React, { useState } from 'react'

/*
Let Checkbox the controls its own state.
Styling 'custom-radio', but only make the borders square in .scss file.
*/
const CheckboxInput = ({ name, label, value, checked, onChange }) => {
    // const [isChecked, setIsChecked] = useState(false);

    // const toggleCheck = e => {
    //     setIsChecked(() => !isChecked);
    // };

    return (
        <div className='flex gap-5 '>
            <input
                type="radio"
                className="custom-radio "
                name={name}
                id={name}
                checked={checked}
                onChange={onChange}
                value={value}


            />
            <label htmlFor={name} className='w-[80%] sm:text-sm sm:w-[100%] text-left'>
                <span>{label}</span>
            </label>
        </div>
    );
};



export default ({ name, label, value, checked, onChange, error }) => (
    <div className="App">
        <CheckboxInput name={name} label={label} value={value} checked={checked} onChange={onChange} />
        <p className=' text-red-500 mulish text-left text-sm'>{error}</p>
    </div >
);