import React, { useContext, useState } from 'react';
import MainLogo from '../../assets/825_logo.png'
import { useLocation, useNavigate } from "react-router-dom";
import SolidButton from './SolidButton';
import LineButton from './LineButton';
import { UserContext } from '../../context/UserContext';
import { buttonHide } from './buttonHide';

const GlobalNav = ({ style, xRef, yRef, aRef, bRef }) => {
    const { user, setUser } = useContext(UserContext)
    const navigate = useNavigate();
    let location = useLocation()

    const onPillar = (e) => {
        e.preventDefault();
        // navigate(item.to)
        xRef.current.scrollIntoView({ behavior: "smooth", });
    };
    const onAbout = (e, item) => {
        e.preventDefault();
        // navigate(item.to)
        yRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const onMilestones = (e, item) => {
        e.preventDefault();
        // navigate(item.to)
        aRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const onPartners = (e, item) => {
        e.preventDefault();
        // navigate(item.to)
        bRef.current.scrollIntoView({ behavior: "smooth", });

    };



    const [menu, setMenu] = useState([
        {
            id: 1,
            name: "Pillars",
            to: "#Pillars",
            ref: 'pillarRef'

        },
        {
            id: 2,
            name: "About the VCC",
            to: "#About",
            ref: 'aboutRef'
        },
        {
            id: 3,
            name: "Milestones",
            to: "#Milestones",
            ref: 'milestoneRef'
        },
        {
            id: 4,
            name: "VCC Partners",
            to: "#Partners",
            ref: 'partnersRef'
        },
    ])


    const handleApply = () => {
        console.log('Apply clicked')
        navigate(user ? '/Apply-Step-1' : '/Apply-Login')
    }
    const handleLogin = () => {
        console.log('Login clicked')
        navigate('/Apply-Login')
    }
    const handleLogout = () => {
        setUser(null)
        localStorage.removeItem('user')
        console.log('Login clicked')
        navigate('/')
    }
    const onClickPillars = (item, e) => {
        console.log(item)
        e.preventDefault();
        (item.ref).current.scrollIntoView({ behavior: "smooth" });
        console.log(item.ref)
    };



    return (
        <div className='flex w-[100vw] sm:px-4 pt-5 pb-5 items-center md:w-[100%] fixed left-0 right-0 z-30 bg-white border-b-[1px] border-gray-200' style={{ style }}>
            <div className='w-full md:w-[100%]  flex mx-[10%] lg:mx-5 md:mx-[5%] sm:mx-3 items-center justify-between'>
                <div className='h-24'>

                    <img src={MainLogo} alt='825_logo' className='h-[100%] cursor-pointer'
                        onClick={() => {
                            navigate('/')
                        }} />

                </div>
                <div className='flex gap-x-20 sm:hidden lg:text-sm lg:gap-x-5 md:hidden' >
                    <button
                        onClick={onPillar}
                        className='font-semibold montserrat hover:text-[#00A3FF]'>
                        <span>
                            Pillar
                        </span>
                    </button>
                    <button
                        onClick={onAbout}
                        className='font-semibold montserrat hover:text-[#00A3FF]'>
                        <span>
                            About the VCC
                        </span>
                    </button>
                    <button
                        onClick={onMilestones}
                        className='font-semibold montserrat hover:text-[#00A3FF]'>
                        <span>
                            Milestones
                        </span>
                    </button>
                    <button
                        onClick={onPartners}
                        className='font-semibold montserrat hover:text-[#00A3FF]'>
                        <span>
                            VCC Partners
                        </span>
                    </button>
                </div>
                {/* <div className='flex gap-x-20'>
                    {menu.map((item) => {
                        return (<button
                            key={item.id}

                            onClick={onPillar()}
                            className='font-semibold montserrat hover:text-[#00A3FF]'
                        >
                            <span
                            // style={{ color: `${item.to}` == location?.pathname ? "#00A3FF" : "#000" }}

                            >
                                {item.name}
                            </span>


                        </button>);
                    })}


                </div> */}
                <div className='flex gap-5'>
                    {
                        user ? <>
                            <div className='text-gray-700 montserrat sm:hidden font-medium capitalize flex items-center'>
                                Hi, {" "}{user.firstname}
                            </div>

                            <SolidButton
                                buttonTitle={'Logout'}
                                textColor='#FFFFFF'
                                btnbgColor='#3CB11C'
                                handleClick={() => { handleLogout() }}
                            // onClick={navigate('/Apply-Step-1')}
                            />
                        </> : <div className='flex gap-4'>

                            <div><LineButton
                                buttonTitle={'Login'}
                                onClick={() => { handleLogin() }}
                            />
                            </div>
                            {buttonHide ?
                                <div className='sm:hidden'>
                                    <SolidButton
                                        buttonTitle={'Apply now'}
                                        textColor='#FFFFFF'
                                        btnbgColor='#3CB11C'
                                        handleClick={() => { handleApply() }}
                                    // onClick={navigate('/Apply-Step-1')}
                                    />
                                </div> : <></>}
                        </div>


                    }



                </div>
            </div>

        </div>
    )
}

export default GlobalNav