import React from 'react'
import GlobalFooter from './components/GlobalFooter'
import GlobalNav from './components/GlobalNav'
import LogoHeader from './components/LogoHeader'
import smileBg from '../assets/smiler.jpg'

const PrivacyPolicy = () => {
    return (
        <div className='overscroll-none'>
            <GlobalNav />

            <div className='h-[fit-content] sm:pt-[20%] sm:w-[100%] flex'>
                <div className='h-[screen] sm:hidden  -z-10 w-[25%] bg-[#ADDC02]'>

                </div>
                <div className='container sm:w-[100%]  mx-auto px-5 sm:px-0 relative pt-[13%]'>


                    <div className='flex  justify-center'>
                        <LogoHeader
                            header={'2023 Privacy Policy'}
                            description={'Please read and agree to these terms  to participate.'}
                        />

                    </div>
                    <div className=' absolute right-0 top-[20]  justify-center'>
                        <div className='h-[25vh] w-[100%] mb-5' style={{
                            backgroundImage: `url(${smileBg})`, backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",

                            backgroundPosition: "center",
                        }}>

                        </div>
                    </div>
                    <div className='flex justify-center mb-10'>
                        <div className='w-[80%]'>



                            <p align="justify" className='leading-normal sm:px-0 mb-3 text-2xl text-center text-[#58B43F] px-20 pb-10 '>
                                <span ><strong>
                                    Privacy Policy for the Value Creation Challenge powered by Old
                                    Mutual Zimbabwe Limited (OMZIL)
                                </strong></span>
                            </p>

                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong> 1. Ownership and Intellectual Property rights</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                1.1. An applicant entering the challenge should be the owner of the
                                project/business idea and cannot enter into the challenge on behalf of a
                                third party.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                1.2. All participants in the challenge undertake that they are the lawful
                                owners of the ideas submitted and no liability, blame or responsibility
                                will attach to Old Mutual in the event that the contrary is proved.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                1.3. In the event of misrepresentation or fraud of an idea or project, all
                                blame rests solely with the applicant who submitted the idea. Old Mutual
                                reserves the right to take down any infringing ideas, work, or part thereof
                                until any infringement matter or dispute is finalised.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                1.4. All shortlisted participants in this challenge must not further
                                disclose the ideas submitted to the challenge without Old Mutual’s consent
                                for the duration of the challenge. Old Mutual will not unnecessarily
                                withhold its consent to such disclosure.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                1.5. Old Mutual does not claim to hold copyright or other ownership to
                                projects and ideas submitted during the challenge. However, by
                                participating in the challenge, the Participants grant Old Mutual and
                                others acting on Old Mutual’s behalf, the worldwide, non-exclusive,
                                perpetual, irrevocable, royalty-free, sub-licensable, transferable right to
                                use, exercise, and commercialize a summary of the project(s) or idea(s)
                                while acknowledging authorship and/or ownership of the project by the
                                applicant(s). The Participants are responsible for the accuracy of the
                                information submitted into the challenge.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong>2. Data Usage</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                2.1. The personal data submitted by the applicant can be used by Old
                                Mutual and its partners to communicate any upcoming events or offers by Old
                                Mutual and they will treat information relating to the Applicant as
                                confidential, but the participant consents to the transfer and disclosure
                                by Old Mutual of any information relating to the Applicant and its business
                                to and between the representative offices, affiliates and agents of old
                                Mutual, wherever situated, for confidential use and in connection with the
                                provision of any additional services that Old Mutual and its affiliates
                                offer, for data processing, statistical and risk analysis purposes. Old
                                Mutual and any representative office, affiliate, agent or third party may
                                transfer and disclose any such information as required by any law, court,
                                regulator or legal process.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                2.2. Old Mutual does not claim to hold copyright or other ownership to
                                projects and ideas submitted during the challenge. However, by
                                participating in the challenge, the Participants grant Old Mutual and
                                others acting on Old Mutual’s behalf, the worldwide, non-exclusive,
                                perpetual, irrevocable, royalty-free, sub-licensable, transferable right to
                                use, exercise, and commercialize a summary of the project(s) or idea(s)
                                while acknowledging authorship and/or ownership of the project by the
                                applicant(s). The Participants are responsible for the accuracy of the
                                information submitted into the challenge
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong> 3. Indemnification</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                3.1. All Participants, and winners shall indemnify and keep Old Mutual,
                                its directors, affiliates, members, partners, employees, agents,
                                consultants, suppliers, contractors and sponsors indemnified against any
                                loss or damages, ,any claims, actions, liabilities, suits, injuries ,
                                fines, penalties, costs, expenses including attorney’s fees and other costs
                                arising from this challenge.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                3.2. Prior to awarding any Seed Capital, Old Mutual may require the
                                Participants to sign such an indemnity.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                11.3. The Participants shall further indemnify Old Mutual, its affiliates,
                                directors, agents, consultants and employees and keep them indemnified
                                against any damages, loss, costs (including legal costs on a Legal
                                Practitioner-client scale) it may suffer as a result of the Applicant’s
                                ideas or projects infringing any third-party intellectual property rights.
                                <br />
                            </p>

                        </div>
                    </div>

                </div>
            </div>

            <GlobalFooter />
        </div >
    )
}

export default PrivacyPolicy