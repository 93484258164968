import React from 'react'
import GlobalFooter from './components/GlobalFooter'
import GlobalNav from './components/GlobalNav'
import LogoHeader from './components/LogoHeader'
import smileBg from '../assets/smiler.jpg'

const TermsAndConditions = () => {
    return (
        <div className='overscroll-none'>
            <GlobalNav />

            <div className='h-[fit-content]   sm:pt-[20%] flex'>
                <div className='h-[screen] sm:hidden  -z-10 w-[25%] bg-[#ADDC02]'>

                </div>
                <div className='container mx-auto sm:px-0  px-5 relative  pt-[13%]'>


                    <div className='flex  justify-center'>
                        <LogoHeader
                            header={'2023 Terms & Conditions'}
                            description={'Please read and agree to these terms  to participate.'}
                        />

                    </div>
                    <div className=' absolute right-0 top-[20]  justify-center'>
                        <div className='h-[25vh] w-[100%] mb-5' style={{
                            backgroundImage: `url(${smileBg})`, backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",

                            backgroundPosition: "center",
                        }}>

                        </div>
                    </div>
                    <div className='flex justify-center mb-10'>
                        <div className='w-[80%]'>



                            <p align="justify" className='leading-normal mb-3 text-2xl sm:p-0 sm:mb-[10%] text-center text-[#58B43F] px-20 pb-10 '>
                                <span ><strong>
                                    Terms and Conditions for the Value Creation Challenge powered by Old
                                    Mutual Zimbabwe Limited (OMZIL)
                                </strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong> 1. Introduction</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                These Terms and Conditions shall be binding on you when entering the Value
                                Creation Challenge powered by Old Mutual Zimbabwe Limited (Old Mutual), its
                                partners or affiliates. These terms apply to all participants. Your
                                application to enter the challenge shall be deemed an unconditional
                                acceptance by you of these Terms. By applying to enter the challenge, you
                                will be deemed to have read and understood these Terms and agree to be
                                bound by them. All entry instructions and any other documentation and
                                specific details relating to the challenge, or the awards shall form part
                                of these Terms. By applying to enter the challenge, you agree to co-operate
                                and comply with all reasonable requests of Old Mutual, its partners, agents
                                and employees for any information or in connection with this challenge, the
                                competition and its broadcast.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong> 2. The Challenge &amp; Eligibility</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                2.1.  The Eight2Five Value Creation Challenge is a nationwide challenge
                                powered by Old Mutual in partnership with British Council, British Embassy
                                and Electronic Funds Transfer Corporation (EFT) to support businesses and
                                ideas that transform how we do business, help address socio-economic issues
                                and improve the society of Zimbabwe as a whole.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                2.2. The Challenge is open, without charge, to any citizen of Zimbabwe.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                2.3. The participants shall complete the application process to enter the
                                challenge which is found on the    <a href="http//www.eight2five.co.zw"><u>www.eight2five.co.zw</u></a>
                                website
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                2.4.  Employees of the Old Mutual group, or their immediate family members
                                shall not qualify for the challenge. Family members shall refer to spouses,
                                children, grandchildren and siblings and their families
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                2.5. The Applicant should have ownership rights over the business or idea
                                they submit for the challenge.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong>3.The Application Process</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                3.1. The Value Creation Challenge shall commence on the 10 February 2023 at
                                0800 am
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                3.2. 3.2 The Application Process shall begin as soon as the application
                                portal is open on the 16<sup>th</sup> of December 2022
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                3.3. Submissions of application forms will automatically close on the
                                deadline which is 10 February 2023 at 2359hrs and any further applications
                                after this date and time will not be accepted. Participant submissions will
                                only be accepted through the Application Form that will be available on
                                this URL
                                <a href="https//vcc.eight2five.co.zw/apply/">
                                    <u>https//vcc.eight2five.co.zw/apply/</u>
                                </a>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                3.4. No responsibility will be taken by Old Mutual for any applications
                                that are delayed in transit or lost for technical or other reasons or
                                received after the closing date and time.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong> 4.The Evaluation Process</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.1. The evaluation criteria shall be as follows
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.1.1. Viability of Business Model
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.1.2.
                                Business management skills
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.1.3. Potential for growth
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.1.4. Short term and long-term competition risk
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.1.5. Market Risk, Team Risk and Founder Risk
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.2. The evaluation shall be done in 5 key steps as follows
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.2.1. Applications will be considered by a selected committee according to
                                the selection criteria outlined above in section 4.1 during February- March
                                2023.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.2.2. The selected participants will be informed by email within 7 working
                                days of the closing date, and must respond within 7 days to proceed to the
                                next stage of the Challenge
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.2.3. Top 25 Shortlisted participants will be invited to on-site (or
                                virtual) workshops in March 2023
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.2.4. Following the workshops, the shortlisted participants will be
                                invited to a pitch to be part of the Incubation Program. The pitch will be
                                held in the second week of March 2023 and those selected to participate in
                                the incubation program will be announced shortly after.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                4.2.5. The incubation Program will run from March 2023 to August 2023
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong> 5. The Eight2Five Incubation Program and Seed Capital Awards</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                5.1. Old Mutual shall award Seed Capital to Participants who perform the
                                best in the incubation program
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                5.2. Ten (10 ) companies will be selected to participate in the Eight2Five
                                Incubation Program
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                5.3. Companies that are selected to participate in the Eight2Five
                                Incubation Program will be awarded the following
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                5.3.1. . The top three (3) will each receive up to an amount in local
                                currency equivalent to USD $ 10,000 (converted using the auction rate) in
                                Seed Capital. This seed capital award will be conditional on the following
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                5.3.1.1. The seed capital shall be paid in local currency equivalent,
                                converted using the auction rate and will be paid through a CABS account.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                5.3.1.2. The top Three (3) that receive the Seed Capital will be paid out
                                in stages upon the successful completion of agreed upon milestones of the
                                incubation program. The stages will be determined after a business health
                                check is performed on the individual businesses.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                5.3.2. Office space allocation at the Eight2Five Hub for a period of six
                                (6) months free of charge for the Top 10 participants
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                5.3.3. Access to Business development services in the form of Marketing,
                                accounting, human resources, investment, intellectual property and legal
                                &amp; governance workshops as well as mentoring for the top 10 participants
                                for a period of six months.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                5.3.4. Participants of the incubation program may be requested to sign some
                                contracts with Old Mutual governing their participation in the same.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong>6. Application Timelines- Key dates</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                6.1. Application Process begins – 7 February 2023
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                6.2. Top 50 Announcement – April 2023
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                6.3. Top 25 Announcement- 26 April 2023
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                6.4. On-site and Virtual workshops for Top 25 shortlisted participants –
                                April 2023
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                6.5. Top 25 Shortlisted participants pitch – 4<sup>th</sup> May 2023
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                6.6. Incubation Program participants announced – 4<sup>th</sup> May 2023
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                6.7. Incubation Program- May- October 2023
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong>7. Confidentiality</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                The applicant will be required to disclose if they currently have any
                                pending partnerships and equity agreements relating to the submitted idea
                                or business.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong>8. Publicity</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                Participants may be required by Old Mutual to participate in a photo, video
                                and/or film session. The Participants consent and acknowledge that Old
                                Mutual has the right to use such publicity photos, videos and/or films in
                                any medium and in any reasonable manner it sees fit, unless the Applicant
                                informs Old Mutual at the time of entering the competition that he or she
                                wishes to retain his or her anonymity. Entries and material submitted in
                                connection with any contest (whether in written, audio, electronic or
                                visual form, or any combination of those) or any photographs, video and/or
                                film footage and/or audio recording taken of participants become the
                                property of Old Mutual which may use the material in any medium and in any
                                reasonable manner it sees fit. Copyright in any such material remains the
                                sole property of Old Mutual and Old Mutual may publicise, broadcast or
                                otherwise disclose an Applicant’s name, statements, images, or any
                                recording of his or her voice in advertising or promotional activities
                                concerning the competition.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong> 9. Ownership and Intellectual Property rights</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                9.1. An applicant entering the challenge should be the owner of the
                                project/business idea and cannot enter into the challenge on behalf of a
                                third party.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                9.2. All participants in the challenge undertake that they are the lawful
                                owners of the ideas submitted and no liability, blame or responsibility
                                will attach to Old Mutual in the event that the contrary is proved.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                9.3. In the event of misrepresentation or fraud of an idea or project, all
                                blame rests solely with the applicant who submitted the idea. Old Mutual
                                reserves the right to take down any infringing ideas, work, or part thereof
                                until any infringement matter or dispute is finalised.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                9.4. All shortlisted participants in this challenge must not further
                                disclose the ideas submitted to the challenge without Old Mutual’s consent
                                for the duration of the challenge. Old Mutual will not unnecessarily
                                withhold its consent to such disclosure.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                9.5. Old Mutual does not claim to hold copyright or other ownership to
                                projects and ideas submitted during the challenge. However, by
                                participating in the challenge, the Participants grant Old Mutual and
                                others acting on Old Mutual’s behalf, the worldwide, non-exclusive,
                                perpetual, irrevocable, royalty-free, sub-licensable, transferable right to
                                use, exercise, and commercialize a summary of the project(s) or idea(s)
                                while acknowledging authorship and/or ownership of the project by the
                                applicant(s). The Participants are responsible for the accuracy of the
                                information submitted into the challenge.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong>10. Data Usage</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                10.1. The personal data submitted by the applicant can be used by Old
                                Mutual and its partners to communicate any upcoming events or offers by Old
                                Mutual and they will treat information relating to the Applicant as
                                confidential, but the participant consents to the transfer and disclosure
                                by Old Mutual of any information relating to the Applicant and its business
                                to and between the representative offices, affiliates and agents of old
                                Mutual, wherever situated, for confidential use and in connection with the
                                provision of any additional services that Old Mutual and its affiliates
                                offer, for data processing, statistical and risk analysis purposes. Old
                                Mutual and any representative office, affiliate, agent or third party may
                                transfer and disclose any such information as required by any law, court,
                                regulator or legal process.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                10.2. Old Mutual does not claim to hold copyright or other ownership to
                                projects and ideas submitted during the challenge. However, by
                                participating in the challenge, the Participants grant Old Mutual and
                                others acting on Old Mutual’s behalf, the worldwide, non-exclusive,
                                perpetual, irrevocable, royalty-free, sub-licensable, transferable right to
                                use, exercise, and commercialize a summary of the project(s) or idea(s)
                                while acknowledging authorship and/or ownership of the project by the
                                applicant(s). The Participants are responsible for the accuracy of the
                                information submitted into the challenge
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong> 11. Indemnification</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                11.1. All Participants, and winners shall indemnify and keep Old Mutual,
                                its directors, affiliates, members, partners, employees, agents,
                                consultants, suppliers, contractors and sponsors indemnified against any
                                loss or damages, ,any claims, actions, liabilities, suits, injuries ,
                                fines, penalties, costs, expenses including attorney’s fees and other costs
                                arising from this challenge.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                11.2. Prior to awarding any Seed Capital, Old Mutual may require the
                                Participants to sign such an indemnity.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                11.3. The Participants shall further indemnify Old Mutual, its affiliates,
                                directors, agents, consultants and employees and keep them indemnified
                                against any damages, loss, costs (including legal costs on a Legal
                                Practitioner-client scale) it may suffer as a result of the Applicant’s
                                ideas or projects infringing any third-party intellectual property rights.
                                <br />
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                <span><strong> 12. General</strong></span>
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                12.1. The dates and time periods provided in this document may be subject
                                to change from time to time at the discretion of Old Mutual. The
                                Participants will not have a claim against OMZIL for such change in the
                                timelines.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                12.2. Old Mutual and its subsidiaries shall not be liable for any damages
                                arising from Participants’ participation in the challenge or for any
                                technical malfunctions, failures or delays with respect to the challenge or
                                the announcement of winners.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                12.3. Old Mutual and its partners reserves the absolute right to disqualify
                                or withdraw any awards from a Participant from the competition where it
                                considers that the Participant has acted fraudulently or used improper
                                means to enter the challenge, or a Participant has been found liable by a
                                competent court for breaching another Participant’s intellectual property
                                rights.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                12.4. Old Mutual reserves the right to amend these Terms and Conditions at
                                any time by posting amended Terms and Conditions on the Website.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                12.5. The participant undertakes not to commit, procure, or encourage
                                bribery in relation to this challenge or to obtain any favours from any
                                member of Old Mutual, its partners, agents or the Adjudicating panel. If
                                Old Mutual becomes aware of any such prohibited practices, it shall have
                                the right to immediately disqualify the Participant from participating in
                                the challenge and any other future challenges,
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                12.6. Old Mutual may terminate this challenge at any time at its absolute
                                discretion. In the event of such termination, Old Mutual may, at its
                                absolute discretion, elect not to issue any award in respect of the
                                terminated challenge. Old Mutual accepts no liability (and excludes all
                                liability) for any loss of profit, business, contracts, revenues or
                                anticipated savings or for any special, direct, indirect or consequential
                                loss of any nature howsoever arising from the termination.
                            </p>
                            <p align="justify" className='leading-normal mb-3 text-lg'>
                                12.7. If any provision of these Terms and Conditions is for any reason held
                                to be invalid, illegal or otherwise unenforceable, such unenforceability
                                does not affect any other provision; the Terms and Conditions are then to
                                be construed as if they had never contained the provision(s) in question
                                and are to be interpreted, in so far as possible, in such a way as to
                                maintain their original intent.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <GlobalFooter />
        </div >
    )
}

export default TermsAndConditions