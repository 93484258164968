import React, { forwardRef, useContext } from 'react'
import { MainTitle } from './MainTitle'
import SolidButton from './SolidButton'
import ladyvcc from '../../assets/ladyvcc.png'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { buttonHide } from './buttonHide'



const About = forwardRef((props, ref) => {
    const { user } = useContext(UserContext)
    const navigate = useNavigate();

    const handleApply = () => {
        console.log('Apply clicked')
        navigate(user ? '/Apply-Step-1' : '/Apply-Login')
    }

    return (
        <div ref={ref} className='bg-[#F3F3F3] pt-20 pb-20  relative flex justify-center' id='#About'>
            <div className='container mx-[10%] flex sm:inline-block '>
                <div className='w-[50%] sm:w-[100%] md:w-[100%] lg:w-[100%] pr-20 sm:pr-0 md:pr-0'>
                    <MainTitle
                        SectionTitle={'2022-2023 Challenge Pillars'}
                        SectionHeader={'The VCC (Value Creation Challenge)'}
                        titleColor='#4DAC27'
                        headerColor='#179D6A'
                    />
                    <p className='mb-5 text-sm'>
                        Value Creation Challenge (VCC) is a nationwide incubation program of Eight2Five powered by Old Mutual, in partnership with British Council, British Embassy and EFT.  The program provides professional business development and learning opportunities, connections and financial support to entrepreneurs that have businesses that are no older than 5 years.

                        VCC facilitates business development through product incubation, business acceleration strategies, access to networks and potential investors and seed funding. The programme targets start-ups that operate in the following thematic areas:



                    </p>
                    <ol className="list-decimal pl-10 gap-y-3 flex flex-col mb-10">
                        <li>
                            Emerging Technologies
                        </li>
                        <li>
                            Fintech
                        </li>
                        <li>
                            Creative Industry (Fashion, Music & Literature)
                        </li>
                        <li>
                            Sustainability
                        </li>
                    </ol>
                    {buttonHide ?

                        <SolidButton
                            buttonTitle={'Apply now'}
                            textColor='#FFFFFF'
                            btnbgColor='#179D6A'
                            handleClick={() => { handleApply() }}

                        /> : <></>}


                </div>
                <div className='w-[50%] sm:hidden md:hidden lg:hidden  sm:w-0 absolute bottom-0 right-0'>
                    <img src={ladyvcc} className='h-[60%]' />
                </div>


            </div>

        </div>
    )
}
)
export default About