import React, { useState, forwardRef } from 'react'
import { MainTitle } from './MainTitle';
import eflogo from '../../assets/Eight@fiv-logo.png';
import bclogo from '../../assets/british-council-logo.png';
import belogo from '../../assets/british_council_zim.png';
import omlogo from '../../assets/old_mutual_logo.png';
import eftlogo from '../../assets/eft_logo.png';

const Partners = forwardRef((props, ref) => {
    const [patners, setPatners] = useState([

        {
            id: 1,
            logo: eflogo,
            alt: "eflog",
            to: "https://eight2five.co.zw/"
        },
        {
            id: 2,
            logo: eftlogo,
            alt: "eftlogo",
            to: "https://www.eftcorp.com/"
        },
        {
            id: 3,
            logo: belogo,
            alt: "belogo",
            to: "https://www.gov.uk/world/organisations/british-embassy-harare"
        },
        {
            id: 4,
            logo: bclogo,
            alt: "bclogo",
            to: "https://www.britishcouncil.co.zw/"
        },
        {
            id: 5,
            logo: omlogo,
            alt: "omlogo",
            to: "https://www.oldmutual.co.zw/"
        },
    ])
    return (
        <div className='  bg-[#FFF] pt-20 pb-20 flex justify-center ' ref={ref}>
            <div className='container mx-[10%] md:mx-[5%]'>
                <MainTitle
                    SectionTitle={'2023 Challenge Partners & sponsors'}
                    SectionHeader={'Partners & Sponsors for the VCC 2023'}
                    titleColor='#4DAC27'
                    headerColor='#021D2A'

                />
                <div className='flex justify-between sm:flex-col sm:gap-10 items-center'>
                    {patners.map((item) => {

                        return (<a href={item.to} className='hover:scale-[102%] scale-' target={'_blank'}>
                            <img src={item.logo} alt={item.alt} className='h-16 w-auto md:scale-[65%] sm:scale-[100%]' />
                        </a>
                        )
                    })

                    }
                </div>
            </div >


        </div >
    )
})

export default Partners