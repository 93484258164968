import React, { useState } from 'react';
import loc_orange from '../../assets/loc_icon.svg'
import loc_2 from '../../assets/loc_icon_2.svg'
import loc_3 from '../../assets/loc_icon_3.svg'
import loc_4 from '../../assets/loc_icon_4.svg'
import loc_5 from '../../assets/loc_icon_5.svg'
import loc_6 from '../../assets/loc_icon_6.svg'


const Milestone = () => {
    const [milestone, setMilestone] = useState([
        {
            id: 1,
            icon: loc_orange,
            Dates: "10 Feb - 28 April 2023",
            desc: "CALL OUT : Application process ",
            txtColor: '#FF6B00'
        },
        {
            id: 2,
            icon: loc_2,
            Dates: "19 May 2023 ",
            desc: "TOP 50 Announcement  ",
            txtColor: '#FF006B'
        },
        {
            id: 3,
            icon: loc_3,
            Dates: "24 May 2023",
            desc: "TOP 25 Announcement  ",
            txtColor: '#4DAC27'
        },
        {
            id: 4,
            icon: loc_4,
            Dates: "7-9 June 2023",
            desc: "BOOTCAMP ",
            txtColor: '#00A3FF'
        },
        {
            id: 5,
            icon: loc_5,
            Dates: "May - Aug 2023",
            desc: "Incubation of Top 10",
            txtColor: '#FF0A44'
        },
        {
            id: 6,
            icon: loc_6,
            Dates: "sEPTEMBER 2023",
            desc: "Top 10 Pitch, Top 3 Seed funding Announcement ",
            txtColor: '#D72FD0'
        },
    ])


    return (

        <div className=' grid grid-cols-6 gap-10 sm:flex sm:flex-col md:grid-cols-2  '>
            {milestone.map((item) => {


                return (
                    <div key={item.id} className='hover:opacity-100 opacity-60 md:opacity-100  sm:opacity-100 cursor-pointer flex flex-col items-start gap-y-3'>
                        <img src={item.icon} alt='milestone' className='h-12' />
                        <h1 className='font-bold montserrat uppercase text-[20px]' style={{ color: item.txtColor }}>{item.Dates}</h1>
                        <h1 className='uppercase montserrat text-white text-sm'>{item.desc}</h1>
                    </div>
                );
            })}

        </div>
    )
}

export default Milestone