import React, { useContext, useState } from 'react'
import GlobalFooter from './components/GlobalFooter'
import GlobalNav from './components/GlobalNav'
import LogoHeader from './components/LogoHeader'
import applyImg from '../assets/apply-img-1.jpg'
import RadioText from './components/RadioInput'
import ApplicationsHeader from './components/ApplicationsHeader'
import { useNavigate } from 'react-router-dom'
import { TitledInput, TitledInputDate, TitledInputHalf, TitledInputPass, TitledInputRadio, TitledInputMsg } from './components/TitledInput'
import SolidButton from './components/SolidButton'
import axios from 'axios'
import { UserContext } from '../context/UserContext'


export const ApplicantLogin = () => {
    const { setUser } = useContext(UserContext)
    const [errorMsg, setErrorMsg] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [loginErrorMsg, setloginErrorMsg] = useState()


    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!email &&
            !password) {
            return setErrorMsg('Please note that all fields Must be filled to Proceed')
        }


        setIsLoading(true)
        axios.post('https://vcc3-backend.onrender.com/api/user/login', {
            email: email,
            password: password
        })
            .then((res) => {
                if (res.status === 200) {
                    localStorage.setItem('user', JSON.stringify(res.data))
                    setUser(res.data)
                    navigate('/')
                    setIsLoading(false)
                }


            })
            .catch((error) => {

                setIsLoading(false)
                setloginErrorMsg('Invalid Email or Password. Try again')

            })

    }
    const handleSignUp = (e) => {
        e.preventDefault()

        navigate('/Apply-Sign-Up')
    }




    return (
        <div className=' w-[100%] mx-auto mt-5 sm'>
            {errorMsg && <p className='text-red-500 mulish text-sm'>{errorMsg}</p>}
            <p className=' text-[#3CB11C] font-semibold mulish md-w-[50%]'>PLEASE NOTE: You need to create an account to be able to apply. If you dont have an account please sign up.</p>
            <form className=''>
                <TitledInputHalf
                    title={'Email'}
                    placeholder={''}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TitledInputPass
                    title={'Password'}
                    placeholder={''}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {loginErrorMsg && <p className='text-red-500 mulish text-sm'>{loginErrorMsg}</p>}


                <input type={'submit'} value={isLoading ? 'Please Wait' : 'Login'} className='uppercase  p-2 text-sm hover:opacity-70 bg-[#00A3FF] text-white mt-10 font-semibold cursor-pointer rounded-md flex justify-center w-40' onClick={handleSubmit} />



            </form>
            <div className='mt-5 flex flex-col gap-y-3'>
                <p className='text-gray-600 mulish'>Don't have an applicant Account?</p>

                <SolidButton
                    buttonTitle={'Sign Up'}
                    handleClick={handleSignUp}
                    btnbgColor={'#3CB11C'}
                    textColor='#FFFFFF'
                />
            </div>





        </div>
    )
}


const ApplyLogin = () => {




    return (
        <div className='reletive'>
            <GlobalNav />


            <div className='h-[fit-content] md:h-[100vh] sm:pt-[20%] w-full flex '>
                <div className='h-[screen] pt-[2%] md:pt-10 sm:hidden -z-20 w-[25%] bg-[#ADDC02]'>
                    <img src={applyImg} className='w-[100%] md:mt-50' />

                </div>
                <div className='flex flex-1 flex-col mx-auto px-5 relative pt-[13%]'>


                    <div className='flex sm:w-[100%] sm:justify-start justify-center'>
                        <LogoHeader
                            header={'2023 Application Login'}
                            description={'Please Remember to fill all the fields to complete applying'}
                        />

                    </div>
                    {/* <div className='flex justify-left pl-[10%]' >
                        <RadioText
                            label={'You have read and understood our data privacy policy and the terms & conditions for the  Value Creation Challenge 2023'}

                            name={'AgreeToTerms'} />

                    </div> */}

                    <div className='flex justify-start  w-full' >
                        <ApplicantLogin />
                    </div>


                    <div className='h-[10vh]'>

                    </div>
                </div>
            </div>

            <GlobalFooter />
        </div>
    )
}

export default ApplyLogin