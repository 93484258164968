import React from 'react'

export const IconText = ({ icon, description }) => {
    return (
        <div className='flex gap-5 mb-5 items-center'>
            <img src={icon} className='h-auto w-7' />
            <h1 className='text-base'>
                {description}
            </h1>
        </div>
    )
}
