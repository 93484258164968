
import './App.css';
import { BrowserRouter as Router, Navigate, redirect, Route, Routes, useLocation } from "react-router-dom";
import Home from './pages/Home';
import { useEffect, useState } from 'react';
import { UserContext } from './context/UserContext';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import TermsAndConditions from './pages/TermsAndConditions';
import ApplyStep1 from './pages/ApplyStep1';
import ApplyStep2 from './pages/ApplyStep2';
import ApplyStep3 from './pages/ApplyStep3';
import ApplyStep4 from './pages/ApplyStep4';
import ApplyStep5 from './pages/ApplyStep5';
import ApplyStep6 from './pages/ApplyStep6';
import ApplyLogin from './pages/ApplyLogin';
import ApplySignUp from './pages/ApplySignUp';
// import About from "./page/components/About";
// import HowToSection from "./page/components/HowToSection";
// import Milestones from "./page/components/Milestones";
// import Partners from "./page/components/Partners";
import Slider from './pages/components/Slider';
// import LoginSuccess from './pages/SignSuccess';
import SignSuccess from './pages/SignSuccess';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { ApplyContext } from './context/ApplyContext';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{props.children}</>
};


function App() {


  const [user, setUser] = useState(null);
  const [apply, setApply] = useState(null);

  useEffect(() => {
    if (!user) {
      const client = JSON.parse(localStorage.getItem('user'))
      setUser(client)
    }

    return () => {

    }
  }, [])



  return (
    <UserContext.Provider value={{ user, setUser }}>
      <ApplyContext.Provider value={{ apply, setApply }}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Home />} />

            <Route exact path="/Apply-Step-1" element={user ? <ApplyStep1 /> : <Navigate to='/' />} />
            <Route exact path="/Apply-Step-2" element={user ? <ApplyStep2 /> : <Navigate to='/' />} />
            <Route exact path="/Apply-Step-3" element={user ? <ApplyStep3 /> : <Navigate to='/' />} />
            <Route exact path="/Apply-Step-4" element={user ? <ApplyStep4 /> : <Navigate to='/' />} />
            <Route exact path="/Apply-Step-5" element={user ? <ApplyStep5 /> : <Navigate to='/' />} />
            <Route exact path="/Apply-Step-6" element={user ? <ApplyStep6 /> : <Navigate to='/' />} />
            <Route exact path="/Apply-Login" element={<ApplyLogin />} />
            <Route exact path="/Apply-Sign-Up" element={<ApplySignUp />} />
            <Route exact path="/Sign-Up-Success" element={<SignSuccess />} />
            <Route exact path="/#Pillars" element={<Slider />} />
            <Route exact path="/Terms-&-Conditions" element={<TermsAndConditions />} />
            <Route exact path="/Privacy-Policy" element={<PrivacyPolicy />} />
          </Routes>
        </Router>
      </ApplyContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
