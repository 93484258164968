import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation, HashNavigation, Autoplay } from "swiper";
import SolidButton from "./SolidButton";
import LineButton from "./LineButton";
import { useNavigate } from "react-router-dom";
import { forwardRef, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { buttonHide } from "./buttonHide";


const swiperData = [
  {
    id: 1,
    color: "#ADDC02",
    imagePath: require("../../assets/fin.jpg"),
    subTitle: "2022-2023 Challenge Pillars",
    mainTitle: "Financial Technology (Fintech)",
    desc: 'At its core, fintech is utilized to help companies, business owners, and consumers better manage their financial operations, processes, and lives by utilizing specialized software and algorithms that are used on computers and, increasingly, smartphones. Fintech, the word, is a combination of "financial technology. If you are in the business of  tackling these issues this pillar is for you',
  },
  {
    id: 2,
    color: "#FF0A7F",
    imagePath: require("../../assets/create.jpg"),
    subTitle: "2022-2023 Challenge Pillars",
    mainTitle: "Creative Industry",
    desc: 'The Creative Industry is based on individual creativity, skill and talent. If your business or ideas is operating in or plans to operate in the fashion, music or literature sectors under the creative industry, this pillar is for you! ',
  },
  {
    id: 3,
    color: "#00A3FF",
    imagePath: require("../../assets/tech.jpg"),
    subTitle: "2022-2023 Challenge Pillars",
    mainTitle: "Emerging Technology",
    desc: 'Emerging technology refers to new and breakthrough technologies such as Artificial Intelligence (AI), Internet of Things (IoT), blockchain, big data, cybersecurity, robotics, and virtual reality (VR). If your business or idea utilizes emerging technology in its core functionality, this pillar is for you!',
  },
  {
    id: 4,
    color: "#FFC700",
    imagePath: require("../../assets/sust.jpg"),
    subTitle: "2022-2023 Challenge Pillars",
    mainTitle: "Sustainability Pillar",
    desc: 'We have been seeing human activity be the main drivers of climate change. Climate change refers to long-term shifts in temperatures and weather patterns. If your business or idea helps to combat the affects of climate change then this pillar is for you!       ',
  },
];



const Slider = forwardRef((props, ref) => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate();

  const handleApply = () => {
    console.log('Apply clicked')
    navigate(user ? '/Apply-Step-1' : '/Apply-Login')
  }

  const swiper = useSwiper();
  return (
    <div ref={ref} >
      <div className="h-[80vh] sm:h-[110vh]   md:h-[110vh] "
      // ref={ref}
      // style={{ height: `calc(100vh - 137px)` }}
      >
        <Swiper
          // direction={"vertical"}
          hashNavigation={{
            watchState: true,
          }}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          preventClicks={true}
          preventInteractionOnTransition={true}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation, HashNavigation]}
          className="mySwiper "
        >
          {swiperData.map((data) => {
            return (
              <SwiperSlide>
                <div className="flex  pb-5 items-center h-full sm:flex-col md:flex-col gap-x-[4%] sm:py-5 md:p-2 sm:p-5 mx-[5%] lg:gap-x-[4%] lg:p-0 p-16 ">
                  <div className="flex-1 sm:flex sm:h-[45vh] md:h-[45vh]    ">
                    <div className="h-[50vh]  w-[100%] sm:justify-center  sm:flex  sm:scale-[70%] md:scale-[70%]">
                      <img className="h-[auto] w-[90%] rounded-xl" src={data.imagePath} />
                    </div>
                  </div>
                  <div className="flex-1  sm:pt-0">
                    <div className="pr-10 md:pr-0 lg:pr-0  sm:p-0 text-left md:justify-center md:items-center  md:flex md:flex-col">
                      <p className="text-white text-xl font-bold mb-6">{data.subTitle}</p>
                      <h3 className="font-bold sm:text-xl md:text-4xl sm:mb-2 mb-8" style={{ color: `${data.color}` }}>
                        {data.mainTitle}
                      </h3>
                      <p className="text-white sm:text-sm sm:text-center  md:text-center mb-8">{data.desc}</p>

                      <div className="flex gap-8 items-center">
                        {buttonHide ?
                          <SolidButton btnbgColor={data.color} buttonTitle="APPLY NOW" handleClick={() => { handleApply() }} /> : <></>}
                        {/* <LineButton buttonTitle="NEXT" btnbgColor={data.color} onClick={() => swiper.slideNext()} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>

  );
})

export default Slider