import React, { useContext, useEffect, useState } from 'react'
import GlobalFooter from './components/GlobalFooter'
import GlobalNav from './components/GlobalNav'
import LogoHeader from './components/LogoHeader'
import applyImg from '../assets/apply-img-1.jpg'
import RadioText from './components/RadioInput'
import ApplicationsHeader from './components/ApplicationsHeader'
import { useNavigate } from 'react-router-dom'
import { TitledInput, TitledInputDate, TitledInputPhone, TitledInputHalf, TitledInputRadio, TitledInputMsg, TitledInputEmail } from './components/TitledInput'
import { UserContext } from '../context/UserContext'
import axios from 'axios'
import RadioInput from './components/RadioInput'
import myGa from './myGa'


export const Apply = () => {


    //     myGa();

    //     return () => {

    //     }
    // }, [])



    const { user } = useContext(UserContext)
    const [fullname, setFullname] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const [agreeErrorMsg, setAgreeErrorMsg] = useState()
    const [dob, setDOB] = useState()
    const [gender, setGender] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [address, setAddress] = useState()
    const [whatsapp, setWhatsapp] = useState()
    const [internetAccess, setInternetAccess] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [agreeToTerms, setAgreeToTerms] = useState(false)
    const [mailErrorMsg, setMailErrorMsg] = useState()
    let mailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

    const navigate = useNavigate();

    const handleChange = () => {
        setAgreeToTerms(true)

    }
    console.log('====================================');
    console.log(user);
    console.log('====================================');




    const handleSubmit = (e) => {
        e.preventDefault()
        if (!fullname ||
            !dob ||
            !gender ||
            !phone ||
            !email ||
            !address ||
            !whatsapp ||
            !internetAccess) {
            return setErrorMsg('Please note that all fields Must be filled to Proceed')
        }

        if (agreeToTerms !== true) {

            return setAgreeErrorMsg('Please note that you have to agree to the terms and Conditions to apply')

        }

        if (email.match(mailFormat)) {
            setIsLoading(true)
            axios.post('https://vcc3-backend.onrender.com/api/apply/create', {
                userInfo: user._id,
                fullname,
                dob,
                gender,
                phone,
                email,
                address,
                whatsapp,
                internetAccess,
            })
                .then((res) => {
                    if (res.status === 200) {
                        localStorage.setItem('form_id', JSON.stringify(res.data._id))
                        // setUser(res.data)
                        navigate('/Apply-Step-2')
                    }

                })
                .catch((error) => {

                })
        }
        else return setMailErrorMsg('Please enter a valid email address')
        setInterval(() => {
            setMailErrorMsg(false)
        }, 2000);

    }




    return (
        <div className=' w-[80%] mx-auto sm:w-[100%] md:w-[100%] sm:mt-0  mt-5'>
            <div className='flex justify-left mb-10'  >
                <RadioInput
                    label={'You have read and understood our data privacy policy and the terms & conditions for the  Value Creation Challenge 2023'}

                    name={'AgreeToTerms'}
                    onChange={() => { handleChange() }}
                    error={agreeErrorMsg}

                />

            </div>

            <ApplicationsHeader
                step={'1'}
                title={'Personal Information'}
            />
            {errorMsg && <p className='text-red-500 mulish text-sm'>{errorMsg}</p>}
            <form className='sm:flex sm:flex-col'>
                <TitledInput
                    title={'Name(s) & Surname'}
                    placeholder={'Please provide us with your full name(s) and surname'}
                    onChange={(e) => setFullname(e.target.value)}
                />
                <div className='flex sm:flex sm:flex-col md:flex-col justify-between'>
                    <TitledInputDate
                        title={'Date of Birth'}
                        placeholder={'Please provide us with your full name(s) and surname'}
                        onChange={(e) => setDOB(e.target.value)}
                    />
                    <TitledInputRadio
                        title={'Gender'}
                        onChange={(e) => setGender(e.target.value)}
                    // placeholder={'Please provide us with your full name(s) and surname'}
                    />
                </div>
                <div className='flex justify-between sm:flex sm:flex-col md:flex-col '>
                    <TitledInputPhone
                        title={'Phone Number'}
                        placeholder={'Your Cell Number'}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <div>

                    </div>
                    <TitledInputEmail
                        title={'Email'}
                        placeholder={'Your Email Account  '}
                        onChange={(e) => setEmail(e.target.value)}
                        error={mailErrorMsg}
                    />


                </div>
                <TitledInput
                    title={'Physical Address'}
                    placeholder={'Please provide us with your home address or contact address*'}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <div className='flex justify-between md:flex-col sm:flex sm:flex-col '>
                    <TitledInputPhone
                        title={'WhatsApp Number'}
                        placeholder={'if different from the one above'}
                        onChange={(e) => setWhatsapp(e.target.value)}
                    />
                    <TitledInputHalf
                        title={'Do you have Access to internet'}
                        placeholder={'Type connectioneg Mobile data, WIFI, LAN etc'}
                        onChange={(e) => setInternetAccess(e.target.value)}
                    />
                </div>

                <input type={'submit'} value={isLoading ? 'Please Wait' : 'Proceed to step 2'} className='uppercase  p-2 text-sm hover:opacity-70 bg-[#00A3FF] text-white mt-10 font-semibold cursor-pointer rounded-md flex justify-center w-40' onClick={handleSubmit} />
                <button>Back to Step 1</button>



            </form>




        </div>
    )
}


const ApplyStep1 = () => {


    return (
        <div className='reletive m'>
            <GlobalNav />
            <div className='h-[fit-content] sm:pt-[20%]  md:pt-[5%] w-full flex '>
                <div className='h-[screen] sm:hidden pt-[2%] -z-20 w-[25%] bg-[#ADDC02]'>
                    <img src={applyImg} className='w-[100%]' />

                </div>
                <div className='flex flex-1 flex-col mx-auto sm:mx-1 px-5 relative pt-[13%]'>


                    <div className='flex md:w-[100%] md:justify-start    sm:w-[100%] sm:justify-start justify-center'>
                        <LogoHeader
                            header={'2023 Application Form'}
                            description={'Please Remember to fill all the fields to complete applying.'}
                        />

                    </div>


                    <div className='flex justify-start  w-full' >
                        <Apply />
                    </div>


                    <div className='h-[10vh]'>

                    </div>
                </div>
            </div>

            <GlobalFooter />
        </div>
    )
}

export default ApplyStep1