import React from 'react'

export const MainTitle = ({ SectionHeader, SectionTitle, headerColor, titleColor }) => {
    return (
        <div className='sm:mb-5 mb-10 sm:w-100% '>
            <h3 className='text-xm montserrat sm:text-xs uppercase sm' style={{ color: titleColor }}>{SectionTitle}</h3>
            <h1 className='text-[40px] sm:w-[100%] montserrat sm:text-[20px] font-bold capitalize' style={{ color: headerColor }}>{SectionHeader}</h1>
        </div>
    )
}
