import React from 'react'

export const timerDate = "apr 28, 2023 23:59:00"

const currentDateTime = new Date().getTime();
const countDownDate = new Date(timerDate).getTime();


export const buttonHide = currentDateTime < countDownDate


