import React, { useEffect, useRef } from "react";
import GlobalNav from "./components/GlobalNav";
import GlobalFooter from "./components/GlobalFooter";
import LandingHero from "./components/LandingHero";
import Slider from "./components/Slider";
// import RenderSlider from "./components/RenderSlider";
import About from "./components/About";
import HowToSection from "./components/HowToSection";
import Milestones from "./components/Milestones";
import Partners from "./components/Partners";
import myGa from "./myGa";
// import { HashScroll } from "react-hash-scroll";
import users from './users.json'


console.log('====================================');
console.log(users?.length);
console.log('====================================');



function Home() {



    const xRef = useRef(null);
    const yRef = useRef(null);
    const aRef = useRef(null);
    const bRef = useRef(null);

    useEffect(() => {
        // myGa();
        let url = window.location.href.split("/");
        let target = url[url.length - 1].toLowerCase();
        let element = document.getElementById(target);

        element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    return (
        <div className=''>
            <GlobalNav xRef={xRef} yRef={yRef} aRef={aRef} bRef={bRef} />
            <LandingHero />

            <Slider ref={xRef} />
            {/* <RenderSlider /> */}

            <About ref={yRef} />

            <HowToSection />

            <Milestones ref={aRef} />

            <Partners ref={bRef} />

            <GlobalFooter />
        </div>
    );
}

export default Home;
