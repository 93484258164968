import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';



const ExpiredNotice = () => {
    return (
        <div className="expired-notice montserrat ">
            <span className='text-3xl font-bold uppercase text-red-600 mb-5'>Applications are Closed!</span>
            <p className='text-sm text-gray-500'>For more updates please check with our social media platforms</p>
        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <>
            <p className="mb-2 text-gray-700 mulish">Time left until applications close</p>
            <div className="show-counter">
                <div className="flex gap-10 ">
                    <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />

                    <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />

                    <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />

                    <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
                </div>

            </div>
        </>

    );
};

const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};

export default CountdownTimer;
