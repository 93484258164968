import React from 'react'

const SolidButton = ({ buttonTitle, handleClick, btnbgColor, textColor, }) => {
    return (
        <div className='uppercase [border-2] {border-[#58B43F] border-solid} p-[10px] text-sm hover:opacity-70 font-semibold cursor-pointer rounded-md flex justify-center w-40'
            style={{ backgroundColor: btnbgColor }}
            onClick={handleClick}

        >
            <h1 style={{ color: textColor }}>{buttonTitle}</h1>
        </div>
    )
}

export default SolidButton