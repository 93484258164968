import React, { forwardRef } from 'react'
import { MainTitle } from './MainTitle'
import Milestone from './Milestone'

const Milestones = forwardRef((props, ref) => {
    return (
        <div ref={ref} className=' bg-[#021D2A] pt-20 pb-20 flex justify-center '>
            <div className='container mx-[10%]'>
                <MainTitle
                    SectionTitle={'VCC Timelines'}
                    SectionHeader={'Challenge Milestones'}
                    titleColor='#ADDC02'
                    headerColor='#FFF'
                />
                < Milestone />

            </div>

        </div>
    )
})

export default Milestones