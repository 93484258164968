import React, { useEffect, useState } from 'react'
import GlobalFooter from './components/GlobalFooter'
import GlobalNav from './components/GlobalNav'
import LogoHeader from './components/LogoHeader'
import applyImg from '../assets/apply-img-5.jpg'
import RadioText from './components/RadioInput'
import ApplicationsHeader from './components/ApplicationsHeader'
import { useNavigate } from 'react-router-dom'
import { TitledInput, TitledInputDate, TitledInputHalf, TitledInputRadio, TitledInputMsg } from './components/TitledInput'
import axios from 'axios'
import myGa from './myGa'


export const Apply = () => {


    const [id, setID] = useState()
    const [errorMsg, setErrorMsg] = useState()

    const [tsSkills, setTsSkills] = useState()
    const [tsMembers, setTsMembers] = useState()
    const [tsNumber, setTsNumber] = useState()
    const [tsParticipate, setTsParticipate] = useState()
    const [tsPrevious, setTsPrevious] = useState()
    const [tsAwards, setTsAwards] = useState()
    const [tsAdditions, setTsAdditions] = useState()
    const [tsTsInvestors, setTsInvestor] = useState()
    const [isLoading, setIsLoading] = useState(false)
    console.log(tsSkills)

    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault()
        if (!tsMembers ||
            !tsSkills ||
            !tsNumber ||
            !tsParticipate ||
            !tsPrevious ||
            !tsAwards ||
            !tsAdditions ||
            !tsTsInvestors
        ) {
            return setErrorMsg('Please note that all fields Must be filled to Proceed')
        }
        setIsLoading(true)

        axios.patch(`https://vcc3-backend.onrender.com/api/apply/${id}`, {
            tsSkills,
            tsMembers,
            tsNumber,
            tsParticipate,
            tsPrevious,
            tsAwards,
            tsAdditions,
            tsTsInvestors

        })
            .then((res) => {
                if (res.status === 200) {
                    // localStorage.setItem('user', JSON.stringify(res.data))
                    // setUser(res.data)
                    navigate('/Apply-Step-6')
                }
                console.log(res)
            })
            .catch((error) => {
                console.log('++++++++++++++++')
                console.log(error)
            })

    }

    useEffect(() => {

        // myGa();
        if (!id) {
            const appID = JSON.parse(localStorage.getItem('form_id'))
            setID(appID)
        }

        return () => {

        }
    }, [])


    return (
        <div className=' w-[80%]  sm:w-[100%] md:w-[100%] sm:mt-0 mx-auto mt-5'>
            <ApplicationsHeader
                step={'5'}
                title={'Team And Skills Information '}
            />
            <form className=''>
                <TitledInputMsg
                    title={'Tell us about yourself and your qualifications?  '}
                    placeholder={''}
                    onChange={(e) => setTsSkills(e.target.value)}
                />
                <TitledInputMsg
                    title={'Tell us about your key team members and their roles?  '}
                    placeholder={' '}
                    onChange={(e) => setTsMembers(e.target.value)}
                />
                <TitledInputMsg
                    title={'How many employees are in your company?'}
                    placeholder={''}
                    onChange={(e) => setTsNumber(e.target.value)}
                />
                <TitledInputMsg
                    title={'Why do you want to participate in VCC 2023?'}
                    placeholder={' '}
                    onChange={(e) => setTsParticipate(e.target.value)}
                />
                <TitledInputMsg
                    title={'Have you previously entered VCC? '}
                    placeholder={'If yes please state the year and if you were shortlisted in the Top 50? '}
                    onChange={(e) => setTsPrevious(e.target.value)}
                />
                <TitledInputMsg
                    title={'Have you won any awards or competitions? '}
                    placeholder={' If yes, please list the awards'}
                    onChange={(e) => setTsAwards(e.target.value)}
                />
                <TitledInputMsg
                    title={'Do you currently have any inverstors that have invested in your business? '}
                    placeholder={' If yes, please advise the type of agreement e.g. equity, friends & Family, etc.'}
                    onChange={(e) => setTsInvestor(e.target.value)}
                />
                <TitledInputMsg
                    title={'Additional information that you will want the adjudication committee to know. '}
                    placeholder={'Put N/A if you dont have any thing to add'}
                    onChange={(e) => setTsAdditions(e.target.value)}
                />


                <input type={'submit'} value={isLoading ? 'Please Wait' : 'Submit '} className='uppercase  p-2 text-sm hover:opacity-70 bg-[#00A3FF] text-white mt-10 font-semibold cursor-pointer pl-2 rounded-md flex justify-center w-40' onClick={handleSubmit} />



            </form>




        </div>
    )
}


const ApplyStep5 = () => {




    return (
        <div className='reletive'>
            <GlobalNav />


            <div className='h-[fit-content] sm:w-[100%] sm:mt-0 w-full flex '>
                <div className='h-[screen] sm:hidden pt-[2%] -z-20 w-[25%] bg-[#ADDC02]'>
                    <img src={applyImg} className='w-[100%]' />

                </div>
                <div className='flex flex-1 flex-col mx-auto px-5 relative pt-[13%]'>


                    <div className='flex sm:w-[100%] sm:justify-start justify-center  md:w-[100%] md:justify-start'>
                        <LogoHeader
                            header={'2023 Application Form'}
                            description={'Please Remember to fill in all the fields to complete  your applicaction.'}
                        />

                    </div>
                    {/* <div className='flex justify-left pl-[10%]' >
                        <RadioText
                            label={'You have read and understood our data privacy policy and the terms & conditions for the  Value Creation Challenge 2023'}

                            name={'AgreeToTerms'} />

                    </div> */}

                    <div className='flex justify-start  w-full' >
                        <Apply />
                    </div>


                    <div className='h-[10vh]'>

                    </div>
                </div>
            </div>

            <GlobalFooter />
        </div>
    )
}

export default ApplyStep5