import React, { useContext } from 'react'
import { MainTitle } from './MainTitle'
import { IconText } from './IconText'
import tick from '../../assets/tick.svg'
import howto from '../../assets/howto.png'
import SolidButton from './SolidButton'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { buttonHide } from './buttonHide'

const HowToSection = () => {
    const { user } = useContext(UserContext)
    const navigate = useNavigate();

    const handleApply = () => {
        console.log('Apply clicked')
        navigate(user ? '/Apply-Step-1' : '/Apply-Login')
    }
    return (
        <div className='bg-[#FFF] pt-20 pb-20 relative flex justify-center' id='process'>
            <div className='container mx-[10%] lg:mx-[5%] flex '>
                <div className='w-[50%]  lg:w-[60%] pr-20 sm:w-[100%] sm:pr-0  md:w-[100%] md:pr-0'>
                    <MainTitle
                        SectionTitle={'Application process'}
                        SectionHeader={'Eligibility Criteria'}
                        titleColor='#4DAC27'
                        headerColor='#179D6A'
                    />
                    <IconText
                        icon={tick}
                        description='Must be a Zimbabwean citizen above the age of 18 years'
                    />
                    <IconText
                        icon={tick}
                        description="Must be the business/ idea owner and applications on behalf of a third party will not be accepted"
                    />
                    <IconText
                        icon={tick}
                        description='Business/ idea must fall under any one of the four pillars:Emerging Technologies, Fintech, Creative Industry (Fashion, Music & Literature) and Sustainability (climate change)
                        '

                    />
                    <IconText
                        icon={tick}
                        description='Business is no older than 5 years at the time of applying
                        '

                    />
                    <IconText
                        icon={tick}
                        description='Shortlisted candidates will need to be available for at least 4 days out of every month from May- August 2023
                        '

                    />

                    <p className='text-sm text-[#333333] mb-10'>
                        *Due to the high volume of applications, we will only be able to respond to successful entries.
                    </p>
                    {
                        buttonHide ?

                            <SolidButton
                                buttonTitle={'Apply now'}
                                textColor='#FFFFFF'
                                btnbgColor='#4DAC27'
                                handleClick={() => { handleApply() }}
                            /> : <></>}


                </div>
                <div className='w-[50%] flex items-center sm:hidden md:hidden'>
                    <img src={howto} />
                </div>
            </div>

        </div>
    )
}

export default HowToSection