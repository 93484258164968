import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//icons import
import facebook from '../../assets/facebook.svg'
import instagram from '../../assets/instagram.svg'
import linkedin from '../../assets/linkedin.svg'
import twitter from '../../assets/twitter.svg'

const GlobalFooter = () => {
    const navigate = useNavigate();

    //footer menu here
    const [footerMenu, setFooterMenu] = useState([
        {
            id: 1,
            name: "Terms & Conditions",
            to: "./Terms-&-Conditions",
        },
        {
            id: 2,
            name: "Privacy Policy",
            to: "./Privacy-Policy",
        },
        {
            id: 3,
            name: "Eight2Five Hub",
            to: "https://eight2five.co.zw/",
        },

    ])

    //Social Media links here

    const [socialIcon, setSocialIcon] = useState([
        {
            id: 1,
            name: "facebook",
            to: "https://www.facebook.com/eight2fivehub",
            icon: facebook
        },
        {
            id: 2,
            name: "facebook",
            to: "https://twitter.com/eight2fivehub",
            icon: twitter
        },
        {
            id: 3,
            name: "tiktok",
            to: "https://www.tiktok.com/@eight2fivehub?_t=8Zhhai3ECTG&_r=1",
            icon: instagram
        },
        {
            id: 4,
            name: "Youtube",
            to: "https://youtube.com/@eight2fiveinnovationhub909",
            icon: linkedin
        },

    ])



    const handleClick = (item) => {
        console.log('clicked')
        navigate(item.to)
    }
    return (
        <div className='w-full bg-[#F3F3F3] poppins text-sm text-[021D2A] flex justify-center pt-4 pb-6'>
            <div className='w-10/12 flex mx-[10%] items-center sm:flex sm:flex-col md:flex-col md:gap-y-5 justify-between border-t-[1px] border-black pt-3 pb-3'>
                <div className='sm:text-center sm:mb-5'>
                    &copy;{new Date().getFullYear()} Value Creation Challange | Designed by <a className='text-orange-600' href='https://rilpix.com/' target="_blank" >Rilpix Africa</a>
                </div>
                <div className='flex gap-5 sm:flex sm:flex-col sm:text-center sm:mb-10 sm:justify-center'>
                    {footerMenu.map((item) => {
                        return (<a target='_blank'
                            // onClick={() => { handleClick(item) }}

                            href={item.to}
                        ><button
                            key={item.id}
                            className='font-regular poppins hover:text-orange-600'
                        >
                                <span
                                // style={{ color: `${item.to}` == location?.pathname ? "#00A3FF" : "#000" }}
                                >
                                    {item.name}
                                </span>


                            </button></a>);
                    })}
                </div>
                <div className='flex  items-center gap-9'>
                    {socialIcon.map((item) => {
                        return (<a className='cursor-pointer hover:opacity-70' href={item.to} target="_blank">
                            <img src={item.icon} alt={item.name} />
                        </a>);
                    })}
                </div>

            </div>

        </div>
    )
}

export default GlobalFooter
