import React, { useState } from 'react'
import GlobalFooter from './components/GlobalFooter'
import GlobalNav from './components/GlobalNav'
import LogoHeader from './components/LogoHeader'
import applyImg from '../assets/apply-img-2.jpg'
import RadioText from './components/RadioInput'
import ApplicationsHeader from './components/ApplicationsHeader'
import { useNavigate } from 'react-router-dom'
import { TitledInput, TitledInputDate, TitledInputHalf, TitledInputPass, TitledInputRadio, TitledInputMsg } from './components/TitledInput'
import { data } from 'autoprefixer'
import axios from 'axios'


export const ApplicantSignUp = () => {
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState()
    const [passErrorMsg, setPassErrorMsg] = useState()
    const [mailErrorMsg, setMailErrorMsg] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [surname, setSurname] = useState();
    const [firstname, setFirstname] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordRetype, setPasswordRetype] = useState();

    // let mailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i


    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!email &&
            !password &&
            !surname &&
            !firstname
        ) {
            return setErrorMsg('Please note that all fields Must be filled to Proceed')
        }
        if (passwordRetype !== password) {

            return setPassErrorMsg('Passwords do not match')

        }


        setIsLoading(true)
        let data = JSON.stringify({
            firstname: firstname,
            email: email,
            surname: surname,
            password: password


        })
        let myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json');
        let requestOption = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        }

        axios.post('https://vcc3-backend.onrender.com/api/user', {
            firstname: firstname,
            email: email,
            surname: surname,
            password: password
        })
            .then((res) => {
                console.log(res)

                if (res.status === 200) {
                    navigate('/Sign-Up-Success')
                }

            })
            .catch((error) => {
                console.log(error)

            })

    }

    return (
        <div className='w-[100%] mx-auto mt-5'>
            {errorMsg && <p className='text-red-500 mulish text-sm'>{errorMsg}</p>}
            <form className=''>
                <TitledInputHalf
                    title={'First Name(s)'}
                    placeholder={''}
                    onChange={(e) => setFirstname(e.target.value)}
                />
                <TitledInputHalf
                    title={'Surname'}
                    placeholder={''}
                    onChange={(e) => setSurname(e.target.value)}
                />
                <TitledInputHalf
                    title={'Email'}
                    placeholder={''}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {mailErrorMsg && <p className='text-red-500 mulish text-sm'>{mailErrorMsg}</p>}
                <TitledInputPass
                    title={'New Password'}
                    placeholder={''}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TitledInputPass
                    title={'Retype Password'}
                    placeholder={''}
                    onChange={(e) => setPasswordRetype(e.target.value)}
                />
                {passErrorMsg && <p className='text-red-500 mulish text-sm'>{passErrorMsg}</p>}


                <input type={'submit'} value={isLoading ? 'Please Wait' : 'Sign Up'} className='uppercase  p-2 text-sm hover:opacity-70 bg-[#3CB11C] text-white mt-5 font-semibold cursor-pointer rounded-md flex justify-center w-40' onClick={handleSubmit} />



            </form>




        </div>
    )
}


const ApplySignUp = () => {




    return (
        <div className='reletive'>
            <GlobalNav />


            <div className='h-[fit-content] w-full sm:pt-[20%] flex '>
                <div className='h-[screen] pt-[2%] sm:hidden -z-20 w-[25%] bg-[#ADDC02]'>
                    <img src={applyImg} className='w-[100%]' />

                </div>
                <div className='flex flex-1 flex-col mx-auto px-5 relative pt-[13%]'>


                    <div className='flex sm:w-[100%] sm:justify-start justify-center'>
                        <LogoHeader
                            header={'Application SignUp'}
                            description={'Please Remember to fill all the fields to complete applying'}
                        />

                    </div>
                    {/* <div className='flex justify-left pl-[10%]' >
                        <RadioText
                            label={'You have read and understood our data privacy policy and the terms & conditions for the  Value Creation Challenge 2023'}

                            name={'AgreeToTerms'} />

                    </div> */}

                    <div className='flex justify-start  w-full' >
                        <ApplicantSignUp />
                    </div>


                    <div className='h-[10vh]'>

                    </div>
                </div>
            </div>

            <GlobalFooter />
        </div>
    )
}

export default ApplySignUp