import React from 'react';

const DateTimeDisplay = ({ value, type, isDanger }) => {
    return (
        <div className={isDanger ? 'countdown danger' : 'countdown'}>
            <div className="flex flex-col">
                <span className="font-bold text-3xl text-center text-gray-900">{value}</span>
                <span className="text-gray-500 uppercase font-medium text-sm">{type}</span>
            </div>
        </div>
    );
};

export default DateTimeDisplay;
