import React, { useEffect, useState } from 'react'
import GlobalFooter from './components/GlobalFooter'
import GlobalNav from './components/GlobalNav'
import LogoHeader from './components/LogoHeader'
import applyImg from '../assets/apply-img-4.jpg'
import RadioText from './components/RadioInput'
import ApplicationsHeader from './components/ApplicationsHeader'
import { useNavigate } from 'react-router-dom'
import { TitledInput, TitledInputDate, TitledInputHalf, TitledInputRadio, TitledInputMsg } from './components/TitledInput'
import axios from 'axios'
import myGa from './myGa'


export const Apply = () => {

    // useEffect(() => {
    //     myGa();

    //     return () => {

    //     }
    // }, [])


    const [id, setID] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const [marketTarget, setMarketTarget] = useState()
    const [marketCustomer, setMarketCustomer] = useState()
    const [marketDistribution, setMarketDistribution] = useState()
    const [marketValue, setMarketValue] = useState()
    const [marketCompetition, setMarketCompetition] = useState()
    const [marketPatent, setMarketPatent] = useState()
    console.log(marketTarget)

    const navigate = useNavigate();

    const handleSubmit = (e) => {

        e.preventDefault()
        if (!marketCustomer ||
            !marketTarget ||
            !marketDistribution ||
            !marketValue ||
            !marketCompetition ||
            !marketPatent
        ) {
            return setErrorMsg('Please note that all fields Must be filled to Proceed')
        }
        setIsLoading(true)

        axios.patch(`https://vcc3-backend.onrender.com/api/apply/${id}`, {
            marketTarget,
            marketCustomer,
            marketDistribution,
            marketValue,
            marketCompetition,
            marketPatent,

        })
            .then((res) => {
                if (res.status === 200) {
                    // localStorage.setItem('user', JSON.stringify(res.data))
                    // setUser(res.data)
                    navigate('/Apply-Step-5')
                }
                console.log(res)
            })
            .catch((error) => {
                console.log('++++++++++++++++')
                console.log(error)
            })

    }

    useEffect(() => {
        if (!id) {
            const appID = JSON.parse(localStorage.getItem('form_id'))
            setID(appID)
        }

        return () => {

        }
    }, [])


    return (
        <div className=' w-[80%] sm:w-[100%]  md:w-[100%] sm:mt-0 mx-auto mt-5'>
            <ApplicationsHeader
                step={'4'}
                title={'Market Information Section'}
            />
            {errorMsg && <p className='text-red-500 mulish text-sm'>{errorMsg}</p>}
            <form className=''>
                <TitledInputMsg
                    title={'Who is your target market (500 words only) '}
                    placeholder={''}
                    onChange={(e) => setMarketTarget(e.target.value)}
                />
                <TitledInputMsg
                    title={'Do you currently have customers? '}
                    placeholder={' If yes, please state how many customers you currently have?'}
                    onChange={(e) => setMarketCustomer(e.target.value)}
                />
                <TitledInputMsg
                    title={'How are you distributing your product/ services?'}
                    placeholder={'If you are not yet operational, advise how you plan to distribute your product or services? '}
                    onChange={(e) => setMarketDistribution(e.target.value)}
                />
                <TitledInputMsg
                    title={'What is your unique value proposition? '}
                    placeholder={'What makes you better than your competitors? '}
                    onChange={(e) => setMarketValue(e.target.value)}
                />
                <TitledInputMsg
                    title={'Who are your main competitors?  '}
                    placeholder={''}

                    onChange={(e) => setMarketCompetition(e.target.value)}
                />
                <TitledInputMsg
                    title={'Do you currently have a patent, or plan to patent your product/ service? '}
                    placeholder={'What makes you better than your competitors? '}
                    onChange={(e) => setMarketPatent(e.target.value)}
                />


                <input type={'submit'} value={isLoading ? 'Please Wait' : 'Proceed to step 5'} className='uppercase  p-2 text-sm hover:opacity-70 bg-[#00A3FF] text-white mt-10 font-semibold cursor-pointer rounded-md flex justify-center w-40' onClick={handleSubmit} />



            </form>




        </div>
    )
}


const ApplyStep4 = () => {




    return (
        <div className='reletive'>
            <GlobalNav />


            <div className='h-[fit-content] w-full flex '>
                <div className='h-[screen] sm:hidden pt-[2%] -z-20 w-[25%] bg-[#ADDC02]'>
                    <img src={applyImg} className='w-[100%]' />

                </div>
                <div className='flex flex-1 flex-col mx-auto px-5 relative pt-[13%]'>


                    <div className='flex sm:w-[100%] md:mt-[5%] sm:justify-start justify-center md:w-[100%] md:justify-start'>
                        <LogoHeader
                            header={'2023 Application Form'}
                            description={'Please Remember to fill all the fields to complete applying.'}
                        />

                    </div>
                    {/* <div className='flex justify-left pl-[10%]' >
                        <RadioText
                            label={'You have read and understood our data privacy policy and the terms & conditions for the  Value Creation Challenge 2023'}

                            name={'AgreeToTerms'} />

                    </div> */}

                    <div className='flex justify-start  w-full' >
                        <Apply />
                    </div>


                    <div className='h-[10vh]'>

                    </div>
                </div>
            </div>

            <GlobalFooter />
        </div>
    )
}

export default ApplyStep4